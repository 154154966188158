
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as index3Rmod5NnfqOMPV6XWKZ639514Pp3eioxNfthIof5ePcMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/index.vue?macro=true";
import { default as loginorrGDw6DPw3a4cvY1kZ0_45BE69RvUV8Kle3hZERHjjfQMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/login.vue?macro=true";
import { default as verifyOorvOhGi9I_eYe1Yhmjrxo3b57zRGk3YcryzKStGBSwMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/verify.vue?macro=true";
import { default as sitemap2xgaJckJcC8LB_0qvpYey_455BzUbwVHId9IlF4rTQcuoMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/sitemap.vue?macro=true";
import { default as about_45usiWEwpyc8aDb8JHbzYQbocQWUiVXQxWVSrdxWWM7FgqkMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/about-us.vue?macro=true";
import { default as registerDBQi0rnbKbvZ5wNx3w4LUMGL3ZeK7BMRjr4GxzlzMHUMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/register.vue?macro=true";
import { default as complaints8xl74cVsUGg4JZ12eoz410RHFNLL6zyztPYcXgCcx7YMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/complaints.vue?macro=true";
import { default as contact_45uswo63tPDHDM0XYYuoDzJh_LuPqwLWQo8Hzsb0xYvNWxgMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/contact-us.vue?macro=true";
import { default as indexSkfUj_QFWjiCdTsXmwRwf8wlRxO6nR_45RLHgklp4t1LEMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/media/index.vue?macro=true";
import { default as indexl9gn9mBMo5FsmWDc5P6CaNxKLeI0_45fWnqnLr98H1wO0Meta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/energy/index.vue?macro=true";
import { default as _91slug_9364VTBGjkB3ovUTt0KKNGajM7ey4EgMbWhaVZsUNAbWMMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/media/[slug].vue?macro=true";
import { default as partnershipsoQ6TzhCRJIc8c76blnyrDvtFboDb_45Va2zGC9Upuvm4AMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/partnerships.vue?macro=true";
import { default as terms_45of_45useE91VoWrnzNbg6ycB0KVCMQ1LrM2LVs7cU0iHHhOYLWAMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/terms-of-use.vue?macro=true";
import { default as indexoNvbKU7E6cZyWU9ZqfkdZA7sImySHOR7lwxzNFUiOpgMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/careers/index.vue?macro=true";
import { default as privacy_45policygjzYHwHzS2TNX7A0L_45blpgpe7LL_45T8T5ePpOnKFkwegMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/privacy-policy.vue?macro=true";
import { default as reset_45passwordjLlp7l3KYkgvbADsK0_QaHZkkVI1ysXFXbNR_45JzrHU4Meta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/reset-password.vue?macro=true";
import { default as indexqYCEipd_wsD7EIAXrCCSQtCr_45bE92eouYGI2g80o2ecMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/car-loans/index.vue?macro=true";
import { default as indextRoyJkx0vaWV_emoJQiTULfkMcFbaB5soaJSvBpKlYIMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/dashboard/index.vue?macro=true";
import { default as forgot_45passwordsOOJ3Sa_BOqAR0M2rR5bf4EG3ZORTCl5_45w3LLKrJnHUMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/forgot-password.vue?macro=true";
import { default as indexSmfYv4U4E2fxTTZe486wXeA7hUPlxfs_45wrwwVRB58DYMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/home-loans/index.vue?macro=true";
import { default as _91slug_937273Cz67H_zV0Vp3btv3h6sMNvXoeLHabBYLgGxL1lEMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/[vertical]/[slug].vue?macro=true";
import { default as guidesfW7STIgAYDCbNTaj91Ppmbf0w6ioymWpT_45MwO6_jnUkMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/[vertical]/guides.vue?macro=true";
import { default as collection_45noticeFOg6lTB4m3Zbpw2K1bu_45hTOwMo_i6nAnmi0rpJrNqCcMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/collection-notice.vue?macro=true";
import { default as indexN62_6WPWtljYC9LN7zn_4FFekyHQUKHM029ZFp6ZdtcMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/credit-cards/index.vue?macro=true";
import { default as set_45passwordIhQrRB79eT2Y0l4_45l7ABLaJ6xP8UPnrTiZnAXLCXuGUMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/guest/set-password.vue?macro=true";
import { default as welcome_45backktuvbnhDbIIrBQTtV1ukErN7LoM9SgDmHYcMRfSJdEQMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/guest/welcome-back.vue?macro=true";
import { default as mergelffQJPLuflfPUmOxgc_ihxBYsBqF5PoRVwpGXf85ul4Meta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/authorization/merge.vue?macro=true";
import { default as indexq9m2bS59gF_zN7wxP30Gaxv7Nt883mtRp0E96Hyjt1AMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/car-insurance/index.vue?macro=true";
import { default as _91slug_93jmB14zJOfsglsujML3iK86ocrSQoqs1dOABO_45Kv76C8Meta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/credit-cards/[slug].vue?macro=true";
import { default as post_45sale_45thank_45youLVedBBpHE3ZJIHri95GSThbBmiWjGO_45Ns6vS89w6OvUMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/post-sale-thank-you.vue?macro=true";
import { default as indexwBsQNTpT_45lRVU_bS9IZ7S8WWGEoFBiBk8162HnrZG5sMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/life-insurance/index.vue?macro=true";
import { default as indexyQTdvJaeeCgLTHJUiZklpxwVgEwi4UEEUCM_18hae9AMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/personal-loans/index.vue?macro=true";
import { default as _91slug_93kRbC0SxuTd_gBzptc_yoXXkaltTBJM5T53wYnbhhe_cMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/preview/media/[slug].vue?macro=true";
import { default as index5NbczAp_gyG1sZkhgCJAMQ9sxC_eRyji8lgWf1yxuGEMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/dashboard/perks/index.vue?macro=true";
import { default as indexZ85ZbfzmjunMQOiU762O85FpGohDbOi9lnWgpUi8E_kMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/expert-analysis/index.vue?macro=true";
import { default as _91author_93QgWr1_45iZ5uQCxl5DUszTYLuaI21RLVEUv0c2wCehvn0Meta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/media/author/[author].vue?macro=true";
import { default as callbackqhuM_45aflasCo295MiLwX2eCpXE9lFXh82D_z3fw3E10Meta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/authorization/callback.vue?macro=true";
import { default as buyer_45protection_45claimr2ORIgiurh7V1wqAYDt2yaYvdfsIeqGSfCfvvfuQk9MMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/buyer-protection-claim.vue?macro=true";
import { default as index_45LPfDBs2NoVG0x414KLvhmdmz2_45YcYKbNeSolLZpkeEMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/dashboard/quotes/index.vue?macro=true";
import { default as thank_45youExv8e1DfDxo_fwgir6DGXQV26NW9DP_l0A1WKzHMH8UMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/energy/quote/thank-you.vue?macro=true";
import { default as errorDYdi_457pAzNGJLCY8s5rydDQRUG9ansRJcDjTSuaaRKQMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/error.vue?macro=true";
import { default as index4ChgMEs7NvP5OC7q_455j8ZY2U_4526GSt3clKjZtJ_j3eoMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/index.vue?macro=true";
import { default as indexXKuYVjYTpCB03rSpthclN5m8_iTNEb4fWZ9wja06FscMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/dashboard/rewards/index.vue?macro=true";
import { default as errorc4UroaZGFbjyoj4t1hMmLRVczskz3X7W2J7hX6duYBgMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/energy/application/error.vue?macro=true";
import { default as _91step_93xCdQ2iGEUSF5Fp3F5AZc42xwr9GmqBDQH6HKM3eWI3YMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/energy/quote/form/[step].vue?macro=true";
import { default as detailsAQIhHA0NBtPGeoKcN6YTYkPH3ayvaoQRhJo1vwstQ1UMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/energy/quote/form/details.vue?macro=true";
import { default as interest_45ratesEoo_xR1pEyNBDctJlW5EjFRofUTiuDbiEyCjxYoR058Meta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/home-loans/interest-rates.vue?macro=true";
import { default as _91slug_93YEBwYvoKM2AqVHuz_45kejVv0H_txzUIISdKPCppgjkosMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/preview/[vertical]/[slug].vue?macro=true";
import { default as indexQ4CLDPMy0E4D_CGxhg2FGfflvJZhB6deNbN1ZJV8nQsMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/[vertical]/companies/index.vue?macro=true";
import { default as account_45settingsORPO9GOscDr_pQ2JKj_45MfnkA8qIVejozRKLK6_45A_rPAMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/dashboard/account-settings.vue?macro=true";
import { default as indexYOV53284wd9q2nK9WIGrYHeBJjxhQuwrM5XgacEp4Q4Meta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/dashboard/onboarding/index.vue?macro=true";
import { default as live_45chat5YyS9GSbGBio2JHSQk4Wu7YbIZQPV9Utbc9optV8kCoMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/live-chat.vue?macro=true";
import { default as post_45saleKdrNzGCp15SnVtgBZtBdcU2hDYI36h8OIftFzofxHTYMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/post-sale.vue?macro=true";
import { default as thank_45youKYnWw9zCUlobM5dnmYbMJ4HqpvDP1tZ_45aMUWl9vhrp4Meta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/home-loans/quote/thank-you.vue?macro=true";
import { default as comprehensivervqBHa8Z3_gVrdgHpXQ_Wwt14sMf4eRGoFjrumekVX8Meta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/car-insurance/comprehensive.vue?macro=true";
import { default as _91slug_93cfb7syE3S5IuHd_ocYN7Ec08sSzt0kTl3T_G5ANwHPQMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/credit-cards/preview/[slug].vue?macro=true";
import { default as addrELka_n82tASxLtQaJZTvSTLbws4UG5wn1sR3BG7OqkMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/life-insurance/partners/add.vue?macro=true";
import { default as indexjmRpl8UGuh0gh4ZJ26osQcesfqPRJ2f1PIdDYmNv5lEMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue?macro=true";
import { default as _91response_93lXLx43yXhouH99XiPyA78bK6yi6DgOEIh6sSozqfGXMMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue?macro=true";
import { default as schedule_45callback3JOR2V36m8bhqZQQmmH46P6WTxK09_45RPbkAC_45h6gAq4Meta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/[vertical]/schedule-callback.vue?macro=true";
import { default as _91slug_93XCpW_5JJCMgXwsQF_45IC6irwSDTRMEDhnW3HIoBPu1wEMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue?macro=true";
import { default as thank_45you_aJr3y9ToIIlMgfDGx_QeFGuGeh6TBW_KhPMlKmYFZkMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/energy/application/thank-you.vue?macro=true";
import { default as live_45chat_45bzuwBEs0VLmcqLNXwVP3NB8s_ul2qWF_45dtsZqwpxm2TMMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/live-chat-b.vue?macro=true";
import { default as indexGpambdfNgSQ0p7vJZbhxVicKjaR6RBjqFgRtA8pidC0Meta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/home-loans/calculators/index.vue?macro=true";
import { default as _91step_93ZohGZPOx7K2DPiyn5jQCKlHaXljpEuLQIZeYoiccNt8Meta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/home-loans/quote/form/[step].vue?macro=true";
import { default as _91slug_93_3k4NnfN5GoOGFlyg4ydDjoYY412x5cC_45ClHmR8mfS4Meta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue?macro=true";
import { default as index_45tGP8S0dtyDyqRGskb2IZEuiTyYPD2GlFAaGOXQMAscMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/offers/index.vue?macro=true";
import { default as addAcE6A9LFEleQ7YIRelRRcpLXfmFg4YRXqZtGxjnBGokMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/partners/add.vue?macro=true";
import { default as _91type_93DhD0NQuoeG_45Opw887j93q6yAM57287aO6DIoraxb1B0Meta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/home-loans/calculators/[type].vue?macro=true";
import { default as detailsczIEJOihEm60JyPwpxTl5qPummkK2BC51nuLRC6oB9QMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/home-loans/quote/form/details.vue?macro=true";
import { default as digital_45eic41_2riChvVlVXEhLJN_tlNe6OKaKO5sgsBE_45S4g2A_sMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/energy/application/digital-eic.vue?macro=true";
import { default as thank_45youmY6mHE7uNOFimQ04xxU6jP1xa4QFyO1h_Phk4t5i31AMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue?macro=true";
import { default as _91step_93hfpJgnuyyiV2iaEajA6wf9DWgBOD13C6CR8PuBYr_W8Meta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue?macro=true";
import { default as _91author_93fEzLErRlfV_45ohjwFThAa2W3CFX8V4mapw3NzY7ZU3gQMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/expert-analysis/author/[author].vue?macro=true";
import { default as step09C6ecOQY9zPzd1AlOVhxWbY6Sq8Ut2ffehA_45_45yY1bnkMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/life-insurance/quote/form/step0.vue?macro=true";
import { default as detailslsXEPj46cy_3Nap_45o_45g3_45G10BcLxo6lpkJB8OuSpHI0Meta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/car-insurance/quote/form/details.vue?macro=true";
import { default as resultsfDmtXW9PJBzr8SrocnBN0suI_91PUWFSHt4XUEVqpcUMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/car-insurance/quote/form/results.vue?macro=true";
import { default as indexMYq6Rrmdyuwz4_g1KlVXxs5zhAv_1PynW86PPiI_451vwMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/expert-analysis/[category]/index.vue?macro=true";
import { default as signup8GinVSavQpaeb7h9J4ORCWzrQY4OSuh7Oa2LfRJd8sIMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/partners/signup.vue?macro=true";
import { default as thank_45youKQcVJLGifpRBGNPmAXFYKYInb8_45KNMV2okQMfxKwnsoMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue?macro=true";
import { default as _91step_93VXe9t5tOHWNC8pO3A8bymP2c_jd_6kkUm7NIvC5WVoIMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/life-insurance/quote/form/[step].vue?macro=true";
import { default as _91step_93bXg_45h_4553dhK9EIgD47Yzrh6MKMoMkGpQCpLbDso2XioMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue?macro=true";
import { default as _91slug_93BnVqgpbXHtn48tq5B_450wsNRwk83V4xRFWZI_45JA2DqIcMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue?macro=true";
import { default as step0ymzSVwl6UfY9C30_a3_45ZlmoS1K_JN6Jtpphrq7UbQuwMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue?macro=true";
import { default as existing_45customersKKVDBQlIrvIhXcGzcbh5_rugTMQUVDWGxnz5vPeafskMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/life-insurance/existing-customers.vue?macro=true";
import { default as thank_45youE7xTWx4hhXK418H2EUBw3oeyXeIIu_457Y9NFw5u4AIpgMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue?macro=true";
import { default as detailsWgQDI1OcUHjhvrh1tsWMWzjNlD_45Pjhq_453c6fWzRnSHcMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/life-insurance/quote/form/details.vue?macro=true";
import { default as detailsQNR_df3wDS9n21cNmOVKRTK_Pd7GZxNyxtawAb7R61oMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/personal-loans/quote/form/details.vue?macro=true";
import { default as _91step_93wXYURrFCtLkS2i_FE2pGyvPMRxRRgAHAbpCTDCsKcosMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue?macro=true";
import { default as rebatewhiOKm6_45iuXvcqTOAtjlgYvzqoliuN3ng0x9ETfl3WwMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue?macro=true";
import { default as _91step_93xOCJaPTM9sxNYReNKxZko6Pgz93T2baQZNax1br1tLUMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/application/[step].vue?macro=true";
import { default as detailsZtair_45A01W4DPYOzsjNgwBegVX_c_UvYsujazreDgmIMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/quote/form/details.vue?macro=true";
import { default as step0qvVUyxNRehPxAx_454TdBVl6cYD5KqXdf4NsoBbu30SUEMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue?macro=true";
import { default as indexRRDCo6p4cUXgylBTg_45QSgDbKB0EMlSt7lEnKuLgZhnUMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue?macro=true";
import { default as indexHpuWoD4Y5v9t7BtxmZ_6231ZLmJIOnSmM3RUA_45DctE0Meta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue?macro=true";
import { default as detailsnRGG26LZw_45CSzNtEzRDXXFN5_cs90WFB5XfC9jJNNCoMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue?macro=true";
import { default as terms_45and_45conditionsyx6RMl_45xpULverjQuyaj3dhqyrGJyPBDIt1nbK2x_50Meta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue?macro=true";
import { default as indexHUzaQAMcEAhglCWMxSkuRvQ38LIUQ9mV3E2rilP9vwEMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue?macro=true";
import { default as details_45udUz1w2eHTA6pbxU47_i_QbJYY_POXJzOUWi_45dz4UkMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue?macro=true";
import { default as indexVkaxlSlzbrZo_m3WM28F9eMuKz1JiwxqaJLkeLP_45c0IMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue?macro=true";
import { default as successoZ_JL2H_V21rMfiK_yRawaT9jt7KIIhFYTESCTQ3Z1MMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue?macro=true";
import { default as claim_45offerIrUP_qm2MXlnSZlcaRAsphd_lKKnl9_45xaNMvfwgSAkgMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue?macro=true";
import { default as lic_45detailsNvXZz7fuwtVw0fPc_45iFmtsePTJGCFA2mZhufNYqSR3gMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue?macro=true";
import { default as coffs_45harbourj0fX00BtrA3HME_450B5WrKQVizjOyYPEG_45FmLqCBV4sEMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue?macro=true";
import { default as _91step_93a5dt94vC7K24RVNzdlgSPfIR0hQ_45VYIirsX2V_LG1GQMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue?macro=true";
import { default as indexao_45SMpZPNBUu9zChHxBV_45pq4c1jxLOrPuuRI_455jyfNcMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue?macro=true";
import { default as _91slug_93vvnDYq9BYGKvD_bGbuoENJRidP50_45lpPtA3Sy9f65o0Meta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue?macro=true";
import { default as indexwbIR_45bn02_q8dp9hszBFYQ081pktBRWhBbDOZscNdgMMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/application/payment/index.vue?macro=true";
import { default as _91step_93cGKk935VaEBmKDYmEm40dQgGOd88S5YOcChitP0pNqIMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue?macro=true";
import { default as indexpefSPlb87WT9goajPbFWnGQZ1lsb4hEWwnUGqHrFP00Meta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue?macro=true";
import { default as index8Et7o3H0k6HPsrP776bDGn8vRpWO67_WwyxwfO49UzIMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue?macro=true";
import { default as detailsSaCre_45PQzuQ4pxJRTKW1ULvPh9IUn_tpcHnEvBn_45p7EMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue?macro=true";
import { default as expiredTQjmySCzgKX0e8Qn0EiXZuauaiWzivi_snuYDov8yFIMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue?macro=true";
import { default as indexmz4c_tA9b7hZVTKYPYWvgNaGfMzs6nu5wNf97zlHdY4Meta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue?macro=true";
import { default as thank_45youSEBmYJMuNNSvfHSb70eNQA5SCxUJ__UjAfn0efc15BkMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue?macro=true";
import { default as details0M0y4E1mEPat5j7aoJf9cw8aa4PAUJXSffk0UthUNBQMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue?macro=true";
import { default as confirmationKfcGz07AbzYxBHBI83UCnpRUpdrLT2Vs1NUzqI4smVcMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue?macro=true";
import { default as completionpezGHx9uod_45tfFAeWwd6Tr2zceRaKltWooyrGie1EWgMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue?macro=true";
import { default as quotes_45resultsKSA2RX75NJR0HwKkFs8cPqHXyOJquNkDehE2hcR8FrkMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue?macro=true";
import { default as confirmationqEHUHU5yG5iwz8d4AnFOy5Td5QbkO8pR6aw356lGtLkMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue?macro=true";
import { default as _91step_936IHCMP55Pd8SgXZZsiT3QFOrtwyJPo0_9grN6vt2R5wMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue?macro=true";
import { default as _91step_93r0WJkhsmRY459OMNWYeJvBPMhBy5445i6L0eszPruIcMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue?macro=true";
import { default as _91step_937IM3azBJjiIZkJefJI9b0Lg_45TrDjvuQqdR9NpjEAlwwMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue?macro=true";
import { default as _91step_938MCtfWkjYG14euUiZwHU2vfzTGThyRaWjuyDIb5dBPMMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue?macro=true";
import { default as indexruN918tsUGKoNDjsErh7HDfLWgZOv1IBH_yOYaI4W7oMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue?macro=true";
import { default as additional_45detailsDxGExNIjGFpLiMwsfBSa_oWe4RhcxP_45RvMIRV1MuO7QMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue?macro=true";
import { default as _91step_93sU0JlEshjb2kyVtHiXSrV8Lhh6xqltqNB8Bx1YsLUZkMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue?macro=true";
import { default as _91step_93C2bsMAWx1GZIvMF7cuopl467IBcSk_453pGJ1bAp8oNQMMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue?macro=true";
import { default as indexCln3AD7mehHWKBzbjORqZoDmpxuohHB_45EODi0p7b6dcMeta } from "/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginorrGDw6DPw3a4cvY1kZ0_45BE69RvUV8Kle3hZERHjjfQMeta || {},
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/login.vue")
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/verify.vue")
  },
  {
    name: "sitemap",
    path: "/sitemap",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/sitemap.vue")
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/about-us.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerDBQi0rnbKbvZ5wNx3w4LUMGL3ZeK7BMRjr4GxzlzMHUMeta || {},
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/register.vue")
  },
  {
    name: "complaints",
    path: "/complaints",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/complaints.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/contact-us.vue")
  },
  {
    name: "media",
    path: "/media",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/media/index.vue")
  },
  {
    name: "energy",
    path: "/energy",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/energy/index.vue")
  },
  {
    name: "media-slug",
    path: "/media/:slug()",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/media/[slug].vue")
  },
  {
    name: "partnerships",
    path: "/partnerships",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/partnerships.vue")
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/terms-of-use.vue")
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/careers/index.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/privacy-policy.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordjLlp7l3KYkgvbADsK0_QaHZkkVI1ysXFXbNR_45JzrHU4Meta || {},
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/reset-password.vue")
  },
  {
    name: "car-loans",
    path: "/car-loans",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/car-loans/index.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indextRoyJkx0vaWV_emoJQiTULfkMcFbaB5soaJSvBpKlYIMeta || {},
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/dashboard/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordsOOJ3Sa_BOqAR0M2rR5bf4EG3ZORTCl5_45w3LLKrJnHUMeta || {},
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/forgot-password.vue")
  },
  {
    name: "home-loans",
    path: "/home-loans",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/home-loans/index.vue")
  },
  {
    name: "vertical-slug",
    path: "/:vertical()/:slug()",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/[vertical]/[slug].vue")
  },
  {
    name: "vertical-guides",
    path: "/:vertical()/guides",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "collection-notice",
    path: "/collection-notice",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/collection-notice.vue")
  },
  {
    name: "credit-cards",
    path: "/credit-cards",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/credit-cards/index.vue")
  },
  {
    name: "guest-set-password",
    path: "/guest/set-password",
    meta: set_45passwordIhQrRB79eT2Y0l4_45l7ABLaJ6xP8UPnrTiZnAXLCXuGUMeta || {},
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/guest/set-password.vue")
  },
  {
    name: "guest-welcome-back",
    path: "/guest/welcome-back",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/guest/welcome-back.vue")
  },
  {
    name: "authorization-merge",
    path: "/authorization/merge",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/authorization/merge.vue")
  },
  {
    name: "car-insurance",
    path: "/car-insurance",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/car-insurance/index.vue")
  },
  {
    name: "credit-cards-slug",
    path: "/credit-cards/:slug()",
    meta: _91slug_93jmB14zJOfsglsujML3iK86ocrSQoqs1dOABO_45Kv76C8Meta || {},
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/credit-cards/[slug].vue")
  },
  {
    name: "post-sale-thank-you",
    path: "/post-sale-thank-you",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/post-sale-thank-you.vue")
  },
  {
    name: "life-insurance",
    path: "/life-insurance",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/life-insurance/index.vue")
  },
  {
    name: "personal-loans",
    path: "/personal-loans",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/personal-loans/index.vue")
  },
  {
    name: "preview-media-slug",
    path: "/preview/media/:slug()",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/preview/media/[slug].vue")
  },
  {
    name: "dashboard-perks",
    path: "/dashboard/perks",
    meta: index5NbczAp_gyG1sZkhgCJAMQ9sxC_eRyji8lgWf1yxuGEMeta || {},
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/dashboard/perks/index.vue")
  },
  {
    name: "expert-analysis",
    path: "/expert-analysis",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/expert-analysis/index.vue")
  },
  {
    name: "media-author-author",
    path: "/media/author/:author()",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/media/author/[author].vue")
  },
  {
    name: "authorization-callback",
    path: "/authorization/callback",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/authorization/callback.vue")
  },
  {
    name: "buyer-protection-claim",
    path: "/buyer-protection-claim",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/buyer-protection-claim.vue")
  },
  {
    name: "dashboard-quotes",
    path: "/dashboard/quotes",
    meta: index_45LPfDBs2NoVG0x414KLvhmdmz2_45YcYKbNeSolLZpkeEMeta || {},
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/dashboard/quotes/index.vue")
  },
  {
    name: "energy-quote-thank-you",
    path: "/energy/quote/thank-you",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/energy/quote/thank-you.vue")
  },
  {
    name: "health-insurance-error",
    path: "/health-insurance/error",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/error.vue")
  },
  {
    name: "health-insurance",
    path: "/health-insurance",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/index.vue")
  },
  {
    name: "dashboard-rewards",
    path: "/dashboard/rewards",
    props: indexXKuYVjYTpCB03rSpthclN5m8_iTNEb4fWZ9wja06FscMeta?.props ?? {"verificationNeeded":true},
    meta: indexXKuYVjYTpCB03rSpthclN5m8_iTNEb4fWZ9wja06FscMeta || {},
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/dashboard/rewards/index.vue")
  },
  {
    name: "energy-application-error",
    path: "/energy/application/error",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/energy/application/error.vue")
  },
  {
    name: "energy-quote-form-step",
    path: "/energy/quote/form/:step()",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/energy/quote/form/[step].vue")
  },
  {
    name: "energy-quote-form-details",
    path: "/energy/quote/form/details",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/energy/quote/form/details.vue")
  },
  {
    name: "home-loans-interest-rates",
    path: "/home-loans/interest-rates",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/home-loans/interest-rates.vue")
  },
  {
    name: "preview-vertical-slug",
    path: "/preview/:vertical()/:slug()",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/preview/[vertical]/[slug].vue")
  },
  {
    name: "vertical-companies",
    path: "/:vertical()/companies",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  },
  {
    name: "dashboard-account-settings",
    path: "/dashboard/account-settings",
    meta: account_45settingsORPO9GOscDr_pQ2JKj_45MfnkA8qIVejozRKLK6_45A_rPAMeta || {},
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/dashboard/account-settings.vue")
  },
  {
    name: "dashboard-onboarding",
    path: "/dashboard/onboarding",
    meta: indexYOV53284wd9q2nK9WIGrYHeBJjxhQuwrM5XgacEp4Q4Meta || {},
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/dashboard/onboarding/index.vue")
  },
  {
    name: "health-insurance-live-chat",
    path: "/health-insurance/live-chat",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/live-chat.vue")
  },
  {
    name: "health-insurance-post-sale",
    path: "/health-insurance/post-sale",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/post-sale.vue")
  },
  {
    name: "home-loans-quote-thank-you",
    path: "/home-loans/quote/thank-you",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/home-loans/quote/thank-you.vue")
  },
  {
    name: "car-insurance-comprehensive",
    path: "/car-insurance/comprehensive",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/car-insurance/comprehensive.vue")
  },
  {
    name: "credit-cards-preview-slug",
    path: "/credit-cards/preview/:slug()",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/credit-cards/preview/[slug].vue")
  },
  {
    name: "life-insurance-partners-add",
    path: "/life-insurance/partners/add",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/life-insurance/partners/add.vue")
  },
  {
    name: schedule_45callback3JOR2V36m8bhqZQQmmH46P6WTxK09_45RPbkAC_45h6gAq4Meta?.name,
    path: "/:vertical()/schedule-callback",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/[vertical]/schedule-callback.vue"),
    children: [
  {
    name: "vertical-schedule-callback",
    path: "",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue")
  },
  {
    name: "vertical-schedule-callback-response",
    path: ":response()",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue")
  }
]
  },
  {
    name: "credit-cards-redirect-slug",
    path: "/credit-cards/redirect/:slug()",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue")
  },
  {
    name: "energy-application-thank-you",
    path: "/energy/application/thank-you",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/energy/application/thank-you.vue")
  },
  {
    name: "health-insurance-live-chat-b",
    path: "/health-insurance/live-chat-b",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/live-chat-b.vue")
  },
  {
    name: "home-loans-calculators",
    path: "/home-loans/calculators",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/home-loans/calculators/index.vue")
  },
  {
    name: "home-loans-quote-form-step",
    path: "/home-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/home-loans/quote/form/[step].vue")
  },
  {
    name: "car-insurance-redirect-slug",
    path: "/car-insurance/redirect/:slug()",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue")
  },
  {
    name: "health-insurance-offers",
    path: "/health-insurance/offers",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/offers/index.vue")
  },
  {
    name: "health-insurance-partners-add",
    path: "/health-insurance/partners/add",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/partners/add.vue")
  },
  {
    name: "home-loans-calculators-type",
    path: "/home-loans/calculators/:type()",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/home-loans/calculators/[type].vue")
  },
  {
    name: "home-loans-quote-form-details",
    path: "/home-loans/quote/form/details",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/home-loans/quote/form/details.vue")
  },
  {
    name: "energy-application-digital-eic",
    path: "/energy/application/digital-eic",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/energy/application/digital-eic.vue")
  },
  {
    name: "personal-loans-quote-thank-you",
    path: "/personal-loans/quote/thank-you",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue")
  },
  {
    name: "car-insurance-quote-form-step",
    path: "/car-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue")
  },
  {
    name: "expert-analysis-author-author",
    path: "/expert-analysis/author/:author()",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/expert-analysis/author/[author].vue")
  },
  {
    name: "life-insurance-quote-form-step0",
    path: "/life-insurance/quote/form/step0",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/life-insurance/quote/form/step0.vue")
  },
  {
    name: "car-insurance-quote-form-details",
    path: "/car-insurance/quote/form/details",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/car-insurance/quote/form/details.vue")
  },
  {
    name: "car-insurance-quote-form-results",
    path: "/car-insurance/quote/form/results",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/car-insurance/quote/form/results.vue")
  },
  {
    name: "expert-analysis-category",
    path: "/expert-analysis/:category()",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/expert-analysis/[category]/index.vue")
  },
  {
    name: "health-insurance-partners-signup",
    path: "/health-insurance/partners/signup",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/partners/signup.vue")
  },
  {
    name: "health-insurance-quote-thank-you",
    path: "/health-insurance/quote/thank-you",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue")
  },
  {
    name: "life-insurance-quote-form-step",
    path: "/life-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/life-insurance/quote/form/[step].vue")
  },
  {
    name: "personal-loans-quote-form-step",
    path: "/personal-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue")
  },
  {
    name: "expert-analysis-category-slug",
    path: "/expert-analysis/:category()/:slug()",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue")
  },
  {
    name: "health-insurance-quote-form-step0",
    path: "/health-insurance/quote/form/step0",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue")
  },
  {
    name: "life-insurance-existing-customers",
    path: "/life-insurance/existing-customers",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/life-insurance/existing-customers.vue")
  },
  {
    name: "life-insurance-partners-thank-you",
    path: "/life-insurance/partners/thank-you",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue")
  },
  {
    name: "life-insurance-quote-form-details",
    path: "/life-insurance/quote/form/details",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/life-insurance/quote/form/details.vue")
  },
  {
    name: "personal-loans-quote-form-details",
    path: "/personal-loans/quote/form/details",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/personal-loans/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-form-step",
    path: "/health-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue")
  },
  {
    name: "dashboard-vault-product-rebate",
    path: "/dashboard/vault/:product([^/]*)*/rebate",
    meta: rebatewhiOKm6_45iuXvcqTOAtjlgYvzqoliuN3ng0x9ETfl3WwMeta || {},
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue")
  },
  {
    name: "health-insurance-application-step",
    path: "/health-insurance/application/:step()",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/application/[step].vue")
  },
  {
    name: "health-insurance-quote-form-details",
    path: "/health-insurance/quote/form/details",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-policy-step0",
    path: "/health-insurance/quote/policy/step0",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue")
  },
  {
    name: "health-insurance-quote-resume",
    path: "/health-insurance/quote/resume",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue")
  },
  {
    name: "life-insurance-quote-customer",
    path: "/life-insurance/quote/customer",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue")
  },
  {
    name: "dashboard-vault-product-details",
    path: "/dashboard/vault/:product([^/]*)*/details",
    meta: detailsnRGG26LZw_45CSzNtEzRDXXFN5_cs90WFB5XfC9jJNNCoMeta || {},
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue")
  },
  {
    name: "buyer-protection-terms-and-conditions",
    path: "/buyer-protection/terms-and-conditions",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue")
  },
  {
    name: "health-insurance-quote-customer",
    path: "/health-insurance/quote/customer",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue")
  },
  {
    name: "health-insurance-quote-policy-details",
    path: "/health-insurance/quote/policy/details",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue")
  },
  {
    name: "health-insurance-quotes-results",
    path: "/health-insurance/quotes-results",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue")
  },
  {
    name: "life-insurance-quote-customer-success",
    path: "/life-insurance/quote/customer/success",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue")
  },
  {
    name: "dashboard-offers-vertical-claim-offer",
    path: "/dashboard/offers/:vertical()/claim-offer",
    meta: claim_45offerIrUP_qm2MXlnSZlcaRAsphd_lKKnl9_45xaNMvfwgSAkgMeta || {},
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue")
  },
  {
    name: "dashboard-vault-product-lic-details",
    path: "/dashboard/vault/:product([^/]*)*/lic-details",
    meta: lic_45detailsNvXZz7fuwtVw0fPc_45iFmtsePTJGCFA2mZhufNYqSR3gMeta || {},
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue")
  },
  {
    name: "energy-compare-by-location-coffs-harbour",
    path: "/energy/compare-by-location/coffs-harbour",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue")
  },
  {
    name: "health-insurance-quote-policy-step-step",
    path: "/health-insurance/quote/policy/step/:step()",
    meta: _91step_93a5dt94vC7K24RVNzdlgSPfIR0hQ_45VYIirsX2V_LG1GQMeta || {},
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc",
    path: "/health-insurance/visitors-visa-ovhc",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue")
  },
  {
    name: "preview-expert-analysis-vertical-slug",
    path: "/preview/expert-analysis/:vertical()/:slug()",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue")
  },
  {
    name: "health-insurance-application-payment",
    path: "/health-insurance/application/payment",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/application/payment/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-step",
    path: "/health-insurance/visitors-visa-ovhc/:step()",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue")
  },
  {
    name: "health-insurance-application-thankyou",
    path: "/health-insurance/application/thankyou",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue")
  },
  {
    name: "health-insurance-rate-rise-calculator",
    path: "/health-insurance/rate-rise-calculator",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-details",
    path: "/health-insurance/visitors-visa-ovhc/details",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue")
  },
  {
    name: "health-insurance-application-payment-expired",
    path: "/health-insurance/application/payment/expired",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue")
  },
  {
    name: "life-insurance-income-protection-quote",
    path: "/life-insurance/income-protection/quote",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-thank-you",
    path: "/health-insurance/visitors-visa-ovhc/thank-you",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue")
  },
  {
    name: "life-insurance-income-protection-quote-details",
    path: "/life-insurance/income-protection/quote/details",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-confirmation",
    path: "/health-insurance/visitors-visa-ovhc/confirmation",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue")
  },
  {
    name: "dashboard-vault-product-vertical-completion",
    path: "/dashboard/vault/:product([^/]*)*/:vertical()/completion",
    meta: completionpezGHx9uod_45tfFAeWwd6Tr2zceRaKltWooyrGie1EWgMeta || {},
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-quotes-results",
    path: "/health-insurance/visitors-visa-ovhc/quotes-results",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue")
  },
  {
    name: "life-insurance-income-protection-quote-confirmation",
    path: "/life-insurance/income-protection/quote/confirmation",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue")
  },
  {
    name: "dashboard-vault-product-form-energy-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/energy/step/:step()",
    meta: _91step_936IHCMP55Pd8SgXZZsiT3QFOrtwyJPo0_9grN6vt2R5wMeta || {},
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-generic-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/generic/step/:step()",
    meta: _91step_93r0WJkhsmRY459OMNWYeJvBPMhBy5445i6L0eszPruIcMeta || {},
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-application-step",
    path: "/health-insurance/visitors-visa-ovhc/application/:step()",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-home-loan-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/home-loan/step/:step()",
    meta: _91step_938MCtfWkjYG14euUiZwHU2vfzTGThyRaWjuyDIb5dBPMMeta || {},
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue")
  },
  {
    name: "health-insurance-students-offer-university-form",
    path: "/health-insurance/students-offer/:university()/form",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue")
  },
  {
    name: "dashboard-vault-product-vertical-additional-details",
    path: "/dashboard/vault/:product([^/]*)*/:vertical()/additional-details",
    meta: additional_45detailsDxGExNIjGFpLiMwsfBSa_oWe4RhcxP_45RvMIRV1MuO7QMeta || {},
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue")
  },
  {
    name: "dashboard-vault-product-form-life-insurance-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/life-insurance/step/:step()",
    meta: _91step_93sU0JlEshjb2kyVtHiXSrV8Lhh6xqltqNB8Bx1YsLUZkMeta || {},
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-health-insurance-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/health-insurance/step/:step()",
    meta: _91step_93C2bsMAWx1GZIvMF7cuopl467IBcSk_453pGJ1bAp8oNQMMeta || {},
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue")
  },
  {
    name: "health-insurance-students-offer-university-terms-conditions",
    path: "/health-insurance/students-offer/:university()/terms-conditions",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue")
  },
  {
    name: "Guides",
    path: "/credit-cards/guides",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "Companies",
    path: "/credit-cards/companies",
    component: () => import("/codebuild/output/src2898034111/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  }
]